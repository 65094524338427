<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap v-if="!showMode">
            <v-flex xs12>
                <h1>Relatório de Carregamentos</h1>
                <v-divider class="mb-4" />
            </v-flex>

            <v-flex xs12 sm4 md5>
                <v-autocomplete v-model="selectedClient" :items="clients" item-text="name" item-value="name" label="Cliente" return-object="true" :disabled="!loaded"
                    :loading="!loaded" clearable>
                </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm4 md5>
                <DateInterval @onChange="dates = $event" />
            </v-flex>
            <v-flex xs12 sm4 md2 text-center>
                <v-btn :disabled="processing" color="success" @click="get">Gerar Relatório</v-btn>
            </v-flex>
        </v-layout>
        <v-layout wrap v-else>
            <v-flex xs12 md6 text-center class="d-print-none">
                <DownloadCsv :data="csv" name="relatorio.csv" :labels="csvLabels" delimiter=";">
                    <v-btn color="success" style="width: 100%"><v-icon left dark> download </v-icon>Download em CSV</v-btn>
                </DownloadCsv>
            </v-flex>
            <v-flex xs12 md6 text-center>
                <v-btn @click="showMode = false" color="error" class="mr-4" style="width: 100%"><v-icon left>close</v-icon>Fechar Relatório</v-btn>
            </v-flex>

        </v-layout>

        <v-layout wrap v-if="showMode">
            <v-flex xs12>
                <Incharge></Incharge>
            </v-flex>

            <v-flex xs12 v-if="selectedClient">
                <v-container grid-list-xl>
                    <v-layout wrap>
                        <v-flex xs12 py-0 v-if="selectedClient.name">
                            <strong>Nome:</strong>
                            {{ this.selectedClient.name }}
                        </v-flex>
                        <v-flex xs12 py-0 v-if="selectedClient.cnpj">
                            <strong>CNPJ:</strong>
                            {{ this.selectedClient.cnpj }}
                        </v-flex>
                        <v-flex xs12 py-0 v-if="selectedClient.address">
                            <strong>Endereço:</strong>
                            {{ this.selectedClient.address }}
                        </v-flex>
                        <v-flex xs12 py-0 v-if="selectedClient.cep">
                            <strong>CEP:</strong>
                            {{ this.selectedClient.cep }}
                        </v-flex>
                        <v-flex xs12 py-0 v-if="selectedClient.city">
                            <strong>Cidade:</strong>
                            {{ this.selectedClient.city }}
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>

        <v-progress-linear v-if="processing || !loaded" indeterminate />

        <v-layout wrap v-if="showMode">
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>battery_charging_full</v-icon>
                            {{ total }} kWh
                        </div>
                        Energia consumida
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>score</v-icon>
                            {{ items.length }}
                        </div>
                        Número de carros carregados
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>access_time</v-icon>
                            {{ totalConnected }}
                        </div>
                        Tempo Conectado
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>access_time</v-icon>
                            {{ totalCharger }}
                        </div>
                        Tempo Carregando
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 v-if="items.length > 0">
                <v-data-table :headers="headers" :items="items" :items-per-page="-1" class="elevation-2" hide-default-footer :loading="!items">
                    <v-progress-linear v-slot:progress color="blue" indeterminate />
                    <template v-slot:[`item.car.percent`]="{ item }">{{ item.car.percent ? `${item.car.percent}%` : `` }} </template>
                    <template v-slot:[`item.car.consumption`]="{ item }">{{ item.car.consumption }} kWh</template>
                    <template v-slot:[`item.car.maximumPower`]="{ item }">{{ maximumPower(item.car.maximumPower) }}</template>
                    <template v-slot:[`item.car.minutesConnected`]="{ item }">{{ dateTime.showMinutes(item.car.minutesConnected) }}</template>
                    <template v-slot:[`item.car.minutesCharger`]="{ item }">{{ dateTime.showMinutes(item.car.minutesCharger) }}</template>
                    <template v-slot:[`item.paymentValue`]="{ item }">
                        <v-chip v-if="item.paymentValue > 0" class="ma-2" color="success" text-color="white">
                            <v-avatar left> <v-icon>done</v-icon> </v-avatar>Pago R${{ item.paymentValue.toFixed(2) }}
                        </v-chip>
                        <v-chip v-else-if="item.paymentValue == 0" class="ma-2" color="grey" text-color="white">
                            <v-avatar left> <v-icon>grade</v-icon> </v-avatar>Grátis
                        </v-chip>
                        <v-chip v-else class="ma-2" color="error" text-color="white">
                            <v-avatar left> <v-icon>error</v-icon> </v-avatar>Pendente
                        </v-chip>
                    </template>
                    <template v-slot:[`item.unlock`]="{ item }">
                        <v-chip v-if="item.idUser" label outlined x-small color="primary">USER: {{ item.idUser }}</v-chip>
                        <v-chip v-if="item.car && item.car.nfc" label outlined x-small color="primary">NFC: {{ item.car.nfc }}</v-chip>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import DownloadCsv from "vue-json-csv";

import dateTime from "@/helpers/DateTime";
import DateInterval from "@/components/core/DateInterval";
import Incharge from "@/components/shared/Incharge";

export default {
    components: {
        Incharge,
        DateInterval,
        DownloadCsv,
    },

    data() {
        return {
            dateTime,
            showMode: false,
            loaded: false,
            modal: false,
            dates: [],
            processing: false,
            items: [],
            clients: [],
            selectedClient: null,
            csvLabels: {
                dateTime: "Data",
                minutesConnected: "Tempo Conectado(minutos)",
                minutesCharger: "Tempo Carregando(minutos)",
                consumption: "Consumo(kWh)",
                maximumPower: "Potência Máxima(W)",
                name: "Carregador",
                plug: "plug",
            },
        };
    },

    created() {
        this.$http
            .get("api/v2/point?sort[name]=ASC")
            .then((result) => {
                this.clients = result;
                this.loaded = true;
            })
            .catch(() => {
                this.clients = [];
            });
    },

    methods: {
        get() {
            if (!this.processing) {
                this.processing = true;

                let query = this.dates.length == 2 ? `?dateFrom=${this.dates[0]}&dateTo=${this.dates[1]}` : `?dateFrom=${this.dates[0]}&dateTo=${this.dates[0]}`;

                if (this.selectedClient) {
                    query += `&idPoint=${this.selectedClient.id}`;
                }

                this.$http
                    .get("api/v2/charge" + query)
                    .then((result) => {
                        this.items = result;
                        this.processing = false;
                        this.showMode = true;
                    })
                    .catch(() => {
                        this.processing = false;
                    });
            }
        },
    },

    computed: {
        csv() {
            if (this.items.length > 0) {
                return this.items.map((i) => {
                    return {
                        dateTime: i.dateTime,
                        minutesConnected: i.car.minutesConnected,
                        minutesCharger: i.car.minutesCharger,
                        consumption: i.car.consumption,
                        maximumPower: i.car.maximumPower,
                        name: i.charger.name,
                        plug: i.plug,
                    };
                });
            }

            return null;
        },
        headers() {
            let headers = [
                { text: "Data", value: "dateTime", align: "center" },
                { text: "Tempo Conectado", value: "car.minutesConnected", align: "center" },
                { text: "Tempo Carregando", value: "car.minutesCharger", align: "center" },
                { text: "Consumo", value: "car.consumption", align: "center" },
                { text: "Potência Máxima", value: "car.maximumPower", align: "center" },
                { text: "Porcentagem", value: "car.percent", align: "center" },
                { text: "Status Pagamento", value: "paymentValue", align: "center" },
                { text: "Desbloqueio", value: "unlock", align: "center" },
                { text: "Carregador", align: "left", value: "charger.name", visibility: false },
                { text: "Plug", value: "plug", align: "center" },
            ];

            if (!this.selectedClient) {
                headers.push({
                    text: "Local",
                    align: "left",
                    value: "charger.point.name",
                    visibility: false,
                });
            }

            return headers;
        },
        maximumPower() {
            return (max) => (max > 0 ? `${Math.round(max / 10) / 100} kW` : "-"); // ${Math.round(max / 10) / 100} is to put 2 decimal precision
        },
        total() {
            return Number.parseFloat(
                this.items.reduce(function (sum, i) {
                    return sum + i.car.consumption;
                }, 0)
            ).toFixed(2);
        },
        totalConnected() {
            return this.dateTime.tranformTime(
                Number.parseInt(
                    this.items.reduce(function (sum, i) {
                        return sum + i.car.minutesConnected;
                    }, 0)
                )
            );
        },
        totalCharger() {
            return this.dateTime.tranformTime(
                Number.parseInt(
                    this.items.reduce(function (sum, i) {
                        return sum + i.car.minutesCharger;
                    }, 0)
                )
            );
        },
    },
};
</script>